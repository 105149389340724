import { Link } from 'react-router-dom';

// Using function declaration style which is preferred in React 19
export default function HomePage() {
  return (
    <div>
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-primary to-primary-dark text-white py-20">
        <div className="container-custom">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Advanced Medical IT Solutions for Better Patient Outcomes
            </h1>
            <p className="text-xl mb-8">
              Aussielead delivers innovative technology solutions for healthcare providers, 
              enabling faster diagnosis and treatment for critical conditions.
            </p>
            <div className="flex flex-wrap gap-4">
              <Link to="/services" className="btn bg-white text-primary hover:bg-accent">
                Our Services
              </Link>
              <Link to="/contact" className="btn bg-secondary hover:bg-secondary-dark text-white">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* STEMI Solution Feature */}
      <section className="section bg-white">
        <div className="container-custom">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-4 text-primary">
                STEMI Alert System
              </h2>
              <p className="text-gray-700 mb-6">
                Our flagship service enables hospitals to immediately send R12 ECG reports 
                of AI-identified critical ST-Elevation Myocardial Infarction (STEMI) patients 
                to doctors via Email/SMS.
              </p>
              <ul className="space-y-3 mb-6">
                <li className="flex items-start">
                  <svg className="h-6 w-6 text-secondary mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Rapid notification of critical cardiac events</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-6 w-6 text-secondary mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>AI-powered ECG analysis for accurate detection</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-6 w-6 text-secondary mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span>Seamless integration with hospital systems</span>
                </li>
              </ul>
              <Link to="/services" className="btn btn-primary">
                Learn More
              </Link>
            </div>
            <div className="bg-gray-100 p-8 rounded-lg shadow-lg">
              <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg flex items-center justify-center">
                <div className="text-center p-6">
                  <svg className="h-16 w-16 text-primary mx-auto mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                  </svg>
                  <p className="text-gray-700">ECG Report Visualization</p>
                </div>
              </div>
              <div className="mt-4 text-center text-gray-600">
                <p>Our system delivers critical ECG data directly to medical professionals</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="section bg-accent">
        <div className="container-custom">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-primary">Why Choose Aussielead</h2>
            <p className="text-gray-600 mt-4 max-w-2xl mx-auto">
              We combine medical expertise with cutting-edge technology to create solutions that save lives.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="text-secondary mb-4">
                <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Rapid Response</h3>
              <p className="text-gray-600">
                Our solutions enable faster diagnosis and treatment, critical for time-sensitive conditions like STEMI.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="text-secondary mb-4">
                <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Advanced Security</h3>
              <p className="text-gray-600">
                All our systems are built with medical-grade security to protect sensitive patient information.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="text-secondary mb-4">
                <svg className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Medical Expertise</h3>
              <p className="text-gray-600">
                Our team combines IT excellence with deep understanding of medical workflows and requirements.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="section bg-primary text-white">
        <div className="container-custom text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to transform your medical IT systems?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Contact us today to learn how our solutions can help your hospital save more lives.
          </p>
          <Link to="/contact" className="btn bg-white text-primary hover:bg-accent">
            Get in Touch
          </Link>
        </div>
      </section>
    </div>
  );
} 