import { Link } from 'react-router-dom';

// Using function declaration style which is preferred in React 19
export default function Footer() {
  return (
    <footer className="bg-primary text-white">
      <div className="container-custom py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Aussielead Pty Ltd</h3>
            <p className="mb-2">Innovative IT solutions for medical history</p>
            <p className="mb-2">The Hills District, Sydney Australia</p>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="hover:text-accent">Home</Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-accent">About Us</Link>
              </li>
              <li>
                <Link to="/services" className="hover:text-accent">Services</Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-accent">Contact</Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <p className="mb-2">
              <span className="font-medium">Email:</span>{' '}
              <a href="mailto:admin@aussielead.com" className="hover:text-accent">
                admin@aussielead.com
              </a>
            </p>
            <p className="mb-2">
              <span className="font-medium">Phone:</span>{' '}
              <a href="tel:+61422658838" className="hover:text-accent">
                +61 422 658 838
              </a>
            </p>
          </div>
        </div>
        
        <div className="border-t border-primary-light mt-8 pt-6 text-center">
          <p>&copy; {new Date().getFullYear()} Aussielead Pty Ltd. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
} 